/* App.css */
body, html, #root {
  height: 100%;
  margin: 0;
}

.my-backgorund {
  background-image: url('./assets/img/tsoomtransparente.png');
  background-repeat: repeat;
  background-size: 90px; /* Ajusta el tamaño de la imagen si es necesario */
  animation: moveBackground 10s alternate-reverse infinite;
}

@keyframes moveBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
}

.qr-image {
  
}

.random-background {
  position: relative;
  width: 100%;
  height: 100vh; /* Ajusta la altura según tus necesidades */
  overflow: hidden;
}

.container {
  background-color: #FEDB64; /* Fondo amarillo */
  min-height: 100vh; /* Asegurar que el contenedor principal cubra al menos la altura de la ventana */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo {
  width: 400px; /* Ajusta el tamaño según sea necesario */
}

.title {
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 24px;
}

.qr-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px; /* Espaciado aumentado */
  justify-content: center;
}

.qr-item {
  text-align: center;
  width: 180px; /* Tamaño de los QR aumentado */
}

.qr-item img {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
}

.qr-item p {
  font-size: 20px; /* Tamaño del texto de los nombres aumentado */
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

@media (min-width: 768px) {
  .title {
    font-size: 50px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .qr-container {
    gap: 48px;
  }

  .qr-item {
    width: 250px; /* Ajusta el tamaño según sea necesario */
  }
}

@media (min-width: 1024px) {
  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 80px; /* Tamaño del texto aumentado para pantallas grandes */
  }

  .qr-container {
    gap: 48px; /* Espaciado aumentado para pantallas grandes */
  }

  .qr-item {
    width: 300px; /* Tamaño de los QR aumentado para pantallas grandes */
  }

  .logo {
    width: 400px; /* Tamaño del logo aumentado para pantallas grandes */
  }

  .qr-item p {
    font-size: 24px; /* Tamaño del texto de los nombres aumentado para pantallas grandes */
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 40px; /* Ajuste del tamaño del texto para pantallas pequeñas */
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .qr-container {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    gap: 16px; /* Ajuste del espaciado para pantallas pequeñas */
  }

  .qr-item {
    width: 300px; /* Ajuste del tamaño de los QR para pantallas pequeñas */
  }

  .logo {
    width: 350px; /* Ajuste del tamaño del logo para pantallas pequeñas */
  }

  .qr-item p {
    font-size: 20px; /* Ajuste del tamaño del texto de los nombres para pantallas pequeñas */
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
}
